import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/HomeView')
  },
  {
    path: '/zitie',
    component: () => import('../components/ZiTieView')
  },
  {
    path: '/practice',
    component: () => import('../components/PracticeView')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
