<template>
  <div>
    <MainNavbar ref="mainNavbar"/>
    <router-view/>
  </div>
</template>

<script>
import MainNavbar from './components/MainNavbar.vue'

export default {
  name: 'App',
  components: {
    MainNavbar
  },
  mounted() {
    this.$refs.mainNavbar.selectedItem('zitie')
  },
}
</script>