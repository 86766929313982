<template>
  <div>
    <Menu mode="horizontal" theme="dark" active-name="zitie" @on-select="selectedItem">
      <MenuItem name="zitie">
        字帖
      </MenuItem>
      <MenuItem name="practice">
        每周练习
      </MenuItem>
    </Menu>
    <br>
  </div>

</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    selectedItem(value) {
      this.$router.push('/' + value)
    },
  }
}
</script>
<style lang="less" scoped>
.ivu-menu-item {
  font-size: 20px;
}
</style>

